import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';


function Gallery() {

    
    return (
        <Fragment>

            <Header />

            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{
                backgroundImage: "url('assets/img/gallery.svg')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <div className="container text-center py-5">
                    <h1 className="display-3 text-white mb-4 animated slideInDown">Gallery</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Gallery</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
            
            <div className="container-xxl py-5 px-0">
            <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <p className="section-title bg-white text-center text-primary px-3">Gallery</p>
                        <h1 className="mb-5">Some Pictures from VSPL</h1>
                    </div>

                    <div className="row g-0">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/bg12.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/bg12.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/bg13.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/bg13.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G1.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G1.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G2.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G2.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G3.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G3.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G4.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G4.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G5.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G5.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G9.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G9.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G7.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G7.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G12.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G12.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G13.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G13.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G14.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G14.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G15.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G15.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G16.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G16.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G17.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G17.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G18.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G18.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G19.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G19.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G20.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G20.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G22.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G22.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G23.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G23.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G25.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G25.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G29.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G29.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G27.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G27.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G28.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G28.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        
                        
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g1.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g1.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g2.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g2.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g3.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g3.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g4.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g4.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g5.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g5.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g6.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g6.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g7.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g7.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g8.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g8.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g9.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g9.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/new/g10.png" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/new/g10.png" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                <br></br>
                </div>
                </div>

            <Footer />
        </Fragment>
    )
}

export default Gallery;