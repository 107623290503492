import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';


function Transformingsociety() {
    return (
        <Fragment>

            <Header />
            <div className="container mt-5">
                <div className="header-image">
                    <img src="assets/img/vsp.webp" className="img-fluid" alt="Header Image" />
                </div><br />
                <h1 className="text-center">Coming Soon</h1>
                <p>We are ensuring people’s participation for awareness in the sector by organizing several events thorough educational institutes and NGOs.</p>
            </div>

            <Footer />
        </Fragment>
    )
}

export default Transformingsociety;