import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <Fragment>

      {/*Header*/}
      <div className="container-fluid bg-dark px-0">
        <div className="row g-0 d-none d-lg-flex">
          <div className="col-lg-6 ps-5 text-start">
            <div className="h-100 d-inline-flex align-items-center text-light">
              <span>Follow Us:</span>
              <a className="btn btn-link text-light" href="https://www.facebook.com/vasudhasanrakshan?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-link text-light" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-link text-light" href="https://www.linkedin.com/company/80557301/admin/feed/posts/"><i className="fab fa-linkedin-in"></i></a>
              <a className="btn btn-link text-light" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-instagram"></i></a>
              <a className="btn btn-link text-light" href="https://www.youtube.com/@user-be7dz9jx7d"><i className="fab fa-youtube"></i></a>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="h-100 bg-secondary d-inline-flex align-items-center text-dark py-2 px-4">
              <span className="me-2 fw-semi-bold"><i className="fa fa-phone-alt me-2"></i>Call Us:</span>
              <span>+91-7860402234</span>
            </div>
          </div>
        </div>
      </div>




      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
        <a href="index.html" className="navbar-brand d-flex align-items-center" style={{ color: '#158248' }}>
          {/*<h4 className="m-0"><Link to={"/"}><img  src="assets/img/logo.png" alt="" style={{ height: '70px' ,width:'auto' }}/> Vasudha Sanrakshan</Link></h4>*/}
          <Link to={"/"}><img src="assets/img/logo/finallogo.webp" alt="" className="m-0" style={{ height: '50px', width: '225px' }} /></Link>
        </a>
        <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link to={"/"} className="nav-item nav-link ">Home</Link>
            <Link to={"/about"} className="nav-item nav-link ">About</Link>
            <Link to={"/services"} className="nav-item nav-link ">Services</Link>
            <Link to={"/newsandevents"} className="nav-item nav-link ">News & Events</Link>
            <Link to={"/contact"} className="nav-item nav-link active">Contact</Link>
          </div>
          <div className="border-start ps-4 d-none d-lg-block">
            <button type="button" className="btn btn-sm p-0"><i className="fa fa-search"></i></button>
          </div>
        </div>
      </nav>
      {/*Header*/}

      <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{
        backgroundImage: "url('assets/img/contactus.webp')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
        <div className="container text-center py-5">
          <h1 className="display-3 text-white mb-4 animated slideInDown">Contact</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item"><a href="#">Contact</a></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <p className="section-title bg-white text-center text-primary px-3">Contact Us</p>
            <h1 className="mb-5">If You Have Any Query, Please Contact Us</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h3 className="mb-4">Connect With Us</h3>
              <p className="mb-4">Fill the contact form below.</p>
              <form
                action="https://formspree.io/f/xoqoyqqk"
                method="POST"
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="name" placeholder="Your Name" name="name" />
                      <label for="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control" id="email" placeholder="Your Email" name="email" />
                      <label for="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="subject" placeholder="Subject" name="subject" />
                      <label for="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control" placeholder="Leave a message here" name="message" id="message" style={{ height: '250px' }}></textarea>
                      <label for="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-secondary rounded-pill py-3 px-5" type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="contact-box">
                <h3 className="mb-4">Contact Details</h3>

                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-map-marker-alt text-body"></i>
                  </div>
                  <div className="info">
                    <h6>Corporate Office</h6>
                    <span>304, A1, JKG Palm Court, Greater Noida (West) (201009), UP, IN</span>
                  </div>
                </div>

                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-map-marker-alt text-body"></i>
                  </div>
                  <div className="info">
                    <h6>Registered Office</h6>
                    <span>886, Old katra, Prayagraj, 211002, UP, IN</span>
                  </div>
                </div>

                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-map-marker-alt text-body"></i>
                  </div>
                  <div className="info">
                    <h6>Regional Office</h6>
                    <span>54/1, Shatabdipuram, Gorakhpur, 273014, UP, IN </span>
                  </div>
                </div>

                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-phone-alt text-body"></i>
                  </div>
                  <div className="info">
                    <h6>Call Us</h6>
                    <span>
                      <a href="tel:+917860402234">+91-7860402234</a>,{" "}
                      <a href="tel:+919140575714">9140575714</a>,{" "}
                      <a href="tel:+919140683732">9140683732</a>
                    </span>
                  </div>
                </div>

                <div className="contact-item">
                  <div className="icon">
                    <i className="fa fa-envelope text-body"></i>
                  </div>
                  <div className="info">
                    <h6>Mail Us</h6>
                    <span>
                      <a href="mailto:admin_renewables@vasudhasanrakshan.com">
                        admin_renewables@vasudhasanrakshan.com
                      </a>
                    </span><br></br>
                    <span>
                      <a href="mailto:info@vasudhasanrakshan.com">
                        info@vasudhasanrakshan.com
                      </a>
                    </span>
                  </div>
                </div>

                <div className="contact-item">
                  <div className="icon">

                    <i className="fa fa-map-marked-alt text-body"></i>
                  </div>
                  <div className="info">
                    <h6>Location</h6>

                    <iframe
                      width="100%"
                      height="300"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7004.705338076548!2d77.42528!3d28.6191905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cee4abb9de49b%3A0x287a032bd7ff51f1!2sGaur%20City%202%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201009!5e0!3m2!1sen!2sin!4v1696827110306!5m2!1sen!2sin"
                    ></iframe>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Contact;