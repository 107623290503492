import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';


function Circulareconomy() {
    return (
        <Fragment>

            <Header />
            <div className="container mt-5">
                <div className="header-image">
                    <img src="assets/img/vsp.webp" className="img-fluid" alt="Header Image" />
                </div><br />
                <h1 className="text-center">Coming Soon</h1>
                <p>Now we have involved ourself in achieving a circular economy in Waste Water Treatment sector as a part of mission: “Arth ganga” and in Green Energy by developing and formalizing the chain from raw material availability, to use of end products.</p>
            </div>

            <Footer />
        </Fragment>
    )
}

export default Circulareconomy;