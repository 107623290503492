import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';


function Wastewatermanagement() {
    return (
        <Fragment>

            <Header />
            <div className="container mt-5">
                <div className="header-image">
                    <img src="assets/img/w.webp" className="img-fluid" alt="Header Image" />
                </div><br />
                <h1 className="text-center">Waste Water Treatment and Recycling</h1>
                <p>We are working for treatment, recycling and “monetization” of sewage waste water with ‘state-of-art’ technologies those are proven and are working successfully. It was December 2020 when we started our first commercial project of in-situ municipal sewage treatment through bioremediation. Gradually and steadily we advanced our in-situ treatment technology to Constructed Wetlands, Phyto-rids and Phyto-beds, and continuously we are innovating and integrating our technologies to make it more user friendly. Currently, we have completed around 30 projects by FY 2022-2023 and we are working with around 11 projects across India. Our aim is to develop a circular rotation of water, and minimizing, the extraction of ground water, and draining of any effluents to our ‘holy’ Rivers, strictly following ZERO DISHCHARGE POLICY.</p>
                <h3 className="text-center">Jan Ganga</h3>
                <p>We are not only working at ground but also institutionalizing “Zero Discharge Policy” to our societies and keeping them aware about importance of water conservation, reuse and recycling, with the help of Educational Institutes and NGOs through awareness programs like seminars, webinars and, cultural and competitive events under banner of Namami Gange. </p>
                <br></br>
                <h4 className="text-center">What is Namami Gange?</h4>
                <p>‘Namami Gange Programme’, is an Integrated river rejuvenation mission for the ganga River basin, approved as ‘Flagship Program’ by the Union Government in June 2014 under National Mission for Clean Ganga (NMCG). With a holistic approach, it works on pollution abatement, improving ecological flow and strengthening people-river connect.</p>

                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Arth Ganga</h5>
                                <p className="card-text">Establishing People-River connect through the Bridge of Economy.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Jan Ganga</h5>
                                <p className="card-text">People’s Participation.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Gyan Ganga</h5>
                                <p className="card-text">Research, Policy formulation and knowledge Management.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Nirmal Ganga</h5>
                                <p className="card-text">Unpolluted River.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Aviral Ganga</h5>
                                <p className="card-text">Ecological Flow.</p>
                            </div>
                        </div>
                    </div>
                </div>  <br></br>



            </div>

            <Footer />
        </Fragment>
    )
}

export default Wastewatermanagement;