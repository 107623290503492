import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <Fragment>
            <div className="container-fluid bg-dark px-0">
                <div className="row g-0 d-none d-lg-flex">
                    <div className="col-lg-6 ps-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center text-light">
                            <span>Follow Us:</span>
                            <a className="btn btn-link text-light" href="https://www.facebook.com/vasudhasanrakshan?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-link text-light" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-link text-light" href="https://www.linkedin.com/company/80557301/admin/feed/posts/"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-link text-light" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-link text-light" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-youtube"></i></a>
                            <a className="btn btn-link text-light" href="https://www.youtube.com/@user-be7dz9jx7d"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div className="h-100 bg-secondary d-inline-flex align-items-center text-dark py-2 px-4">
                            <span className="me-2 fw-semi-bold"><i className="fa fa-phone-alt me-2"></i>Call Us:</span>
                            <span>+91-7860402234</span>
                        </div>
                    </div>
                </div>
            </div>




            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
                <a href="index.html" className="navbar-brand d-flex align-items-center" style={{ color: '#158248' }}>
                    {/*<h4 className="m-0"><Link to={"/"}><img  src="assets/img/logo.png" alt="" style={{ height: '70px' ,width:'auto' }}/> Vasudha Sanrakshan</Link></h4>*/}
                    <Link to={"/"}><img src="assets/img/logo/finallogo.webp" alt="" className="m-0" style={{ height: '50px', width: '225px' }} /></Link>
                </a>
                <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to={"/"} className="nav-item nav-link ">Home</Link>
                        <Link to={"/about"} className="nav-item nav-link ">About</Link>
                        <Link to={"/services"} className="nav-item nav-link ">Services</Link>
                        <Link to={"/newsandevents"} className="nav-item nav-link ">News & Events</Link>
                        <Link to={"/contact"} className="nav-item nav-link ">Contact</Link>
                    </div>
                    <div className="border-start ps-4 d-none d-lg-block">
                        <button type="button" className="btn btn-sm p-0"><i className="fa fa-search"></i></button>
                    </div>
                </div>
            </nav>
        </Fragment>
    )
}

export default Header;