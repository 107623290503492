import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <Fragment>
            <div className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-4">Corporate Office</h5>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>304, A1, JKG Palm Court, Greater Noida (West) (201009), UP, IN</p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91-7860402234, 9140575714, 9140683732</p>

                            <div className="d-flex justify-content-center pt-4">
                                <a className="btn btn-square btn-secondary rounded-circle me-2" href="https://www.facebook.com/vasudhasanrakshan?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary rounded-circle me-2" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary rounded-circle me-2" href="https://www.linkedin.com/company/80557301/admin/feed/posts/"><i className="fab fa-linkedin-in"></i></a>
                                <a className="btn btn-square btn-secondary rounded-circle me-2" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary rounded-circle me-2" href="https://www.youtube.com/@user-be7dz9jx7d"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-4">Quick Links</h5>
                            <ul className="list-unstyled">
                                <li> <Link to={"/about"} className="btn btn-link mb-2 ">About</Link></li>
                                <li><Link to={"/services"} className="btn btn-link ">Services</Link></li>
                                <li><Link to={"/newsandevents"} className="btn btn-link ">News & Events</Link></li>
                                <li><Link to={"/contact"} className="btn btn-link ">Contact</Link></li>
                                <li><Link to={"/gallery"} className="btn btn-link ">Gallery</Link></li>
                            </ul>


                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-4">We Are At</h5>
                            <p className="mb-2">Greater Noida</p>
                            <p className="mb-2">Gorakhpur</p>
                            <p className="mb-2">Hisar</p>
                            <p className="mb-2">Karnal</p>
                            <p className="mb-2">Prayagraj</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-4">Connect to Us</h5>
                            <p>Submit your email here...</p>
                            <form
                                action="https://formspree.io/f/xoqoyqqk"
                                method="POST"
                            >
                                <div className="position-relative w-100">
                                    <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" name="email" placeholder="Your email" />
                                    <button type="submit" className="btn btn-secondary py-2 position-absolute top-0 end-0 mt-2 me-2">Submit</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>




            <div className="container-fluid bg-secondary text-body copyright py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a className="fw-semi-bold" href="#">Vasudha Sanrakshan</a>, All Right Reserved.
                        </div>
                        <div className="col-md-6 text-center text-md-end">

                            Created By <a className="fw-semi-bold" href="https://vasudhasanrakshan.com">Team Vasudha Sanrakshan</a>
                        </div>
                    </div>
                </div>
            </div>






            <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up"></i></a>
        </Fragment>
    )
}

export default Footer;