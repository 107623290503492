import React, { useState } from 'react';
import './event.css'; // Import your CSS file for styling

const eventList = [
  {
    id: 1,
    title: 'WBA India Congress 2023',
    date: '12-13 Oct, 2023',
    location: 'Hyatt Regency, New Delhi India',
    image: 'assets/img/ue.svg',
    link: '',
  },
 
  
  // Add more events as needed
];

function Event() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === eventList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? eventList.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="container mt-5">
    <div className="carousel-container-event">
      <div className="carouselevent">
        {eventList.map((event, index) => (
          <div
            key={event.id}
            className={`cardevent ${index === currentIndex ? '' : ''}`}
          >
            <img src={event.image} alt={event.title} />
            <h2>{event.title}</h2>
            <p>Date: {event.date}</p>
            <p>Location: {event.location}</p>
            <a href={event.link}>Learn More</a>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Event;
