import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';


function Bioenergy() {
    return (
        <Fragment>

            <Header />
            <div className="container mt-5">
                <div className="header-image">
                    <img src="assets/img/bioenergycarousel.svg" className="img-fluid" alt="Header Image" />
                </div><br />
                <h1 className="text-center">Bio-Energy</h1>
                <p>Since 2019 we are working for the sustainability of chain responsible for the availability and collection of feed stock [Raw Material], and feasibility of market for the supply of Bio-Energy and other by-products. With the increasing demand for sustainable and green energy, renewable energy sector kickstarted and start booming very fast especially Solar and Wind Energy. Development of Bio-energy sector was lagging behind due to un-organized chain for availability and collection of feed stock. However, bio-energy production is dynamic in the nature, covering almost every sector of energy consumption transforming bio-mass (feedstock) to produce Bio-Gas and other Products like CBG [RNG], Ethanol, Biodiesel and Hydrogen. With this surprising power of Bio-Energy and announcement of ‘World Bio-Fuel Alliance’ by Honorable Prime Minister of India Shri Narendra Modi in G20 meeting held of Delhi in September 2023 it is now booming very fast and increasing demand of Bio-energy products given a rigid support to first pillar of Bio-Energy: that is availability and collection of feedstock.</p>
                <h3 className="text-center">Our Scope of Work</h3>
                <p>Our aim is to provider sustainable and green source of energy that is free from any type of greenhouse gas emission. To share good stance in fulfilling requirement of Vehicle’s fuel grade Natural Gas which is currently being generated from fossil fuel and around 48% of total consumption is being imported to India Natural gas generated from the bio-gas plant after some upgradation is known as Renewable Natural Gas (RNG) or commonly known as Compressed Bio-Gas (CBG) in India.</p>

                <h4 className="text-center">What is CBG or RNG?</h4>
                <p>Bio-gas produced after anaerobic digestion of feedstocks contains around 60% of methane gas [CH4] and 40% around Carbon Dioxide [CO2] and some negligible amount of other compounds. But to have CBG we upgrade bio-gas to Compressed Biogas that is also known as Renewable Natural Gas and it contains more than 90% of CH4  and less than 4% CO2 and some other compounds. CBG obtained is similar to CNG used in vehicles for fuel. Recovered CO2 in the process is Biogenic carbon dioxide and is stored or emitted to atmosphere.</p>
                <h4 className="text-center">Biogenic carbon dioxide (CO2)</h4>
                <p>Biogenic CO2 is carbon dioxide released as a result of the combustion or decomposition of organic material, that is biomass and its derivatives. There is no harm in releasing Biogenic CO2 to atmosphere because it is, in the case, not surplus it is just the part of carbon dioxide chain in the environment. We are not producing it by combustion of any fossil fuel. However, recovered CO2 can be upgraded for using in different industries like: Food Industry, Beverages Industry, Hospitals, laser industry and for Research.  </p>

                

            </div>

            <Footer />
        </Fragment>
    )
}

export default Bioenergy;