import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';

function Services() {
    return (
        <Fragment>

            {/*Header*/}
            <div className="container-fluid bg-dark px-0">
                <div className="row g-0 d-none d-lg-flex">
                    <div className="col-lg-6 ps-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center text-light">
                            <span>Follow Us:</span>
                            <a className="btn btn-link text-light" href="https://www.facebook.com/vasudhasanrakshan?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-link text-light" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-link text-light" href="https://www.linkedin.com/company/80557301/admin/feed/posts/"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-link text-light" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-link text-light" href="https://www.youtube.com/@user-be7dz9jx7d"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div className="h-100 bg-secondary d-inline-flex align-items-center text-dark py-2 px-4">
                            <span className="me-2 fw-semi-bold"><i className="fa fa-phone-alt me-2"></i>Call Us:</span>
                            <span>+91-7860402234</span>
                        </div>
                    </div>
                </div>
            </div>




            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
                <a href="index.html" className="navbar-brand d-flex align-items-center" style={{ color: '#158248' }}>
                    {/*<h4 className="m-0"><Link to={"/"}><img  src="assets/img/logo.png" alt="" style={{ height: '70px' ,width:'auto' }}/> Vasudha Sanrakshan</Link></h4>*/}
                    <Link to={"/"}><img src="assets/img/logo/finallogo.webp" alt="" className="m-0" style={{ height: '50px', width: '225px' }} /></Link>
                </a>
                <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to={"/"} className="nav-item nav-link ">Home</Link>
                        <Link to={"/about"} className="nav-item nav-link ">About</Link>
                        <Link to={"/services"} className="nav-item nav-link active ">Services</Link>
                        <Link to={"/newsandevents"} className="nav-item nav-link ">News & Events</Link>
                        <Link to={"/contact"} className="nav-item nav-link ">Contact</Link>
                    </div>
                    <div className="border-start ps-4 d-none d-lg-block">
                        <button type="button" className="btn btn-sm p-0"><i className="fa fa-search"></i></button>
                    </div>
                </div>
            </nav>
            {/*Header*/}

            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{
                backgroundImage: "url('assets/img/carbon.svg')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }} >
                <div className="container text-center py-5">
                    <h1 className="display-3 text-white mb-4 animated slideInDown">Services</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Services</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <p className="section-title bg-white text-center text-primary px-3">Our Services</p>
                        <h1 className="mb-5">Services That We Offer</h1>
                    </div>
                    <div className="row gy-5 gx-4">
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/bioservice.svg" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/bioenergyicon.svg" alt="" />
                                    </div>
                                    <h5 className="mb-3">Bio Energy</h5>
                                    <p className="mb-4">Bio-energy production is dynamic
                                        in the nature, covering almost
                                        every sector of energy
                                        consumption transforming biomass to produce Bio-Gas and
                                        other Products like Ethanol,
                                        Biodiesel and Hydrogen.</p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/bioenergy"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/1s.webp" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/watericon.svg" alt="" />
                                    </div>
                                    <h5 className="mb-3">Waste Water Management</h5>
                                    <p className="mb-4">We have pioneered ourself in
                                        Waste Water Treatment in a very
                                        natural and indigenous method
                                        with the advancement of our
                                        technology.</p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/wastewatermanagement"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/esgservice.svg" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/esgiconimg1.svg" alt="" />
                                    </div>
                                    <h5 className="mb-3">ESG</h5>
                                    <p className="mb-4">Society is demanding that companies, both private and public, serve a social purpose. Hence, VSPL strives to embrace Environment, Social and Governance (ESG) pillars in each of its initiatives and decision making process in order to ensure the sustainability in society. </p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/esg"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/aboutus.webp" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/traicon.svg" alt="" />
                                    </div>
                                    <h5 className="mb-3">Transforming Society</h5>
                                    <p className="mb-4">We, VSPL, feels people's stake and involvement is the utmost requirement in the development of any nation and we are transforming the Society and Nation by ensuring people's participation for awareness and sustainability in our sector with the help of educational institutes and social organizations. </p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/transformingsociety"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/ciricon.svg" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/2s.webp" alt="" />
                                    </div>
                                    <h5 className="mb-3">Circular Economy</h5>
                                    <p className="mb-4">Now we have involved ourself in achieving a circular economy in Waste Water Treatment sector as a part of mission: “Arth ganga” and in Green Energy by developing and formalizing the chain from raw material availability, to use of end products.</p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/circulareconomy"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}

export default Services;