import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';

function About() {
    return (
        <Fragment>

            {/*Header*/}
            <div className="container-fluid bg-dark px-0">
                <div className="row g-0 d-none d-lg-flex">
                    <div className="col-lg-6 ps-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center text-light">
                            <span>Follow Us:</span>
                            <a className="btn btn-link text-light" href="https://www.facebook.com/vasudhasanrakshan?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-link text-light" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-link text-light" href="https://www.linkedin.com/company/80557301/admin/feed/posts/"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-link text-light" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-link text-light" href="https://www.youtube.com/@user-be7dz9jx7d"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div className="h-100 bg-secondary d-inline-flex align-items-center text-dark py-2 px-4">
                            <span className="me-2 fw-semi-bold"><i className="fa fa-phone-alt me-2"></i>Call Us:</span>
                            <span>+91-7860402234</span>
                        </div>
                    </div>
                </div>
            </div>




            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
                <a href="index.html" className="navbar-brand d-flex align-items-center" style={{ color: '#158248' }}>
                    {/*<h4 className="m-0"><Link to={"/"}><img  src="assets/img/logo.png" alt="" style={{ height: '70px' ,width:'auto' }}/> Vasudha Sanrakshan</Link></h4>*/}
                    <Link to={"/"}><img src="assets/img/logo/finallogo.webp" alt="" className="m-0" style={{ height: '50px', width: '225px' }} /></Link>
                </a>
                <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to={"/"} className="nav-item nav-link ">Home</Link>
                        <Link to={"/about"} className="nav-item nav-link active ">About</Link>
                        <Link to={"/services"} className="nav-item nav-link ">Services</Link>
                        <Link to={"/newsandevents"} className="nav-item nav-link ">News & Events</Link>
                        <Link to={"/contact"} className="nav-item nav-link ">Contact</Link>
                    </div>
                    <div className="border-start ps-4 d-none d-lg-block">
                        <button type="button" className="btn btn-sm p-0"><i className="fa fa-search"></i></button>
                    </div>
                </div>
            </nav>
            {/*Header*/}

            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{
                backgroundImage: "url('assets/img/aboutus.webp')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <div className="container text-center py-5">
                    <h1 className="display-3 text-white mb-4 animated slideInDown">About</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">About</a></li>
                        </ol>
                    </nav>
                </div>
            </div>


            <div className="container mt-5">
                <div className="card">
                    <div className="card-body">
                        <h1 className="card-title">About Vasudha Sanrakshan Pvt. Ltd. (VSPL)</h1>
                        <p className="card-text">VSPL was incorporated on June 26, 2018. Since then, we have been working for the management of natural resources, specifically water, and harnessing green energy from organic waste.</p>
                        <p className="card-text">For the first 2 years (2018-2019), we focused on learning and building our organization in the sector. We collaborated with Chaudhary Charan Singh Haryana Agriculture University (CCSHAU), Hisar, Haryana, across the state of Haryana to understand the potential for the production of Bio-Energy.</p>
                        <h2>Vision</h2>
                        <p className="card-text">Our vision is to be part of the nation's growth by providing sustainable solutions through green technologies for the prevention, management, and harnessing the power of natural resources.</p>
                        <h2>Aim</h2>
                        <p className="card-text">Our aim is to create resources and formalize the chain for the development of a circular economy by transforming and harnessing natural resources through intervention of advanced 'Plug & Play' technologies in two broad sectors:</p>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Waste water treatment and recycling</li>
                            <li className="list-group-item">Production of fuel from Bio-Mass (bio-energy)</li>
                        </ul>
                    </div>
                </div>  <br></br>
                <div class="mt-4">
                    <h2>Watch Our Videos</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/i6YXiUkyNQ4?si=lf1B1NQmTEO2CHG8" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div class="col-md-6">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/BcCL6jE73UQ?si=wvreD0WHg6T6F6VN" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}

export default About;