import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';

function Testimonial() {
    return (
        <Fragment>

            <Header />

            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container text-center py-5">
                    <h1 className="display-3 text-white mb-4 animated slideInDown">Testimonial</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Testimonial</a></li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <p className="section-title bg-white text-center text-primary px-3">Our Team</p>
                        <h1 className="mb-5">Experienced Team Members</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item rounded p-4">
                                <img className="img-fluid rounded mb-4" src="assets/img/team-1.jpg" alt="" />
                                <h5>Adam Crew</h5>
                                <p className="text-primary">Founder</p>
                                <div className="d-flex justify-content-center">
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="team-item rounded p-4">
                                <img className="img-fluid rounded mb-4" src="assets/img/team-2.jpg" alt="" />
                                <h5>Doris Jordan</h5>
                                <p className="text-primary">Veterinarian</p>
                                <div className="d-flex justify-content-center">
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="team-item rounded p-4">
                                <img className="img-fluid rounded mb-4" src="assets/img/team-3.jpg" alt="" />
                                <h5>Jack Dawson</h5>
                                <p className="text-primary">Farmer</p>
                                <div className="d-flex justify-content-center">
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <p className="section-title bg-white text-center text-primary px-3">Testimonial</p>
                        <h1 className="mb-5">What People Say About Our Dairy Farm</h1>
                    </div>
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="testimonial-img">
                                <img className="img-fluid animated pulse infinite" src="assets/img/testimonial-1.jpg" alt="" />
                                <img className="img-fluid animated pulse infinite" src="assets/img/testimonial-2.jpg" alt="" />
                                <img className="img-fluid animated pulse infinite" src="assets/img/testimonial-3.jpg" alt="" />
                                <img className="img-fluid animated pulse infinite" src="assets/img/testimonial-4.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="owl-carousel testimonial-carousel">
                                <div className="testimonial-item">
                                    <img className="img-fluid mb-3" src="assets/img/testimonial-1.jpg" alt="" />
                                    <p className="fs-5">Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.</p>
                                    <h5>Client Name</h5>
                                    <span className="text-primary">Profession</span>
                                </div>
                                <div className="testimonial-item">
                                    <img className="img-fluid mb-3" src="assets/img/testimonial-2.jpg" alt="" />
                                    <p className="fs-5">Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.</p>
                                    <h5>Client Name</h5>
                                    <span className="text-primary">Profession</span>
                                </div>
                                <div className="testimonial-item">
                                    <img className="img-fluid mb-3" src="assets/img/testimonial-3.jpg" alt="" />
                                    <p className="fs-5">Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.</p>
                                    <h5>Client Name</h5>
                                    <span className="text-primary">Profession</span>
                                </div>
                                <div className="testimonial-item">
                                    <img className="img-fluid mb-3" src="assets/img/testimonial-4.jpg" alt="" />
                                    <p className="fs-5">Dolores sed duo clita tempor justo dolor et stet lorem kasd labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et labore et tempor diam tempor erat.</p>
                                    <h5>Client Name</h5>
                                    <span className="text-primary">Profession</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="carousel-item active">
  <img src="customer1.jpg" alt="Customer 1" className="d-block mx-auto"/>
  <div className="carousel-caption">
    <h3>Customer 1</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  </div>
</div>

<div className="carousel-item">
  <img src="customer2.jpg" alt="Customer 2" className="d-block mx-auto"/>
  <div className="carousel-caption">
    <h3>Customer 2</h3>
    <p>Nulla nec eleifend ex, a posuere tortor.</p>
  </div>
</div>



            <Footer />
        </Fragment>
    )
}

export default Testimonial;