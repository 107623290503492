import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';


function Esg() {
    return (
        <Fragment>

            <Header />
            <div className="container mt-5">
                <div className="header-image">
                    <img src="assets/img/ESG.png" className="img-fluid" alt="Header Image" />
                </div><br />
                <h1 className="text-center">Coming Soon</h1>
                <p>Society is demanding that companies, both private and public, serve a social purpose. Hence, 
                    VSPL strives to embrace Environment, Social and Governance (ESG) pillars in each of its initiatives 
                    and decision making process in order to ensure the sustainability in society.</p>
            </div>

            <Footer />
        </Fragment>
    )
}

export default Esg;