
import React, { Fragment } from 'react';
import Footer from './footer';
import { Link } from 'react-router-dom';
import TestimonialSlider from './testimonialslider';

function Index() {

    return (
        <Fragment>

            {/*Header*/}
            <div className="container-fluid bg-dark px-0">
                <div className="row g-0 d-none d-lg-flex">
                    <div className="col-lg-6 ps-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center text-light">
                            <span>Follow Us:</span>
                            <a className="btn btn-link text-light" href="https://www.facebook.com/vasudhasanrakshan?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-link text-light" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-link text-light" href="https://www.linkedin.com/company/80557301/admin/feed/posts/"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-link text-light" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-link text-light" href="https://www.youtube.com/@user-be7dz9jx7d"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div className="h-100 bg-secondary d-inline-flex align-items-center text-dark py-2 px-4">
                            <span className="me-2 fw-semi-bold"><i className="fa fa-phone-alt me-2"></i>Call Us:</span>
                            <span>+91-7860402234</span>
                        </div>
                    </div>
                </div>
            </div>




            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
                <a href="index.html" className="navbar-brand d-flex align-items-center" style={{ color: '#158248' }}>
                    {/*<h4 className="m-0"><Link to={"/"}><img  src="assets/img/logo.png" alt="" style={{ height: '70px' ,width:'auto' }}/> Vasudha Sanrakshan</Link></h4>*/}
                    <Link to={"/"}><img src="assets/img/logo/finallogo.webp" alt="" className="m-0" style={{ height: '50px', width: '225px' }} /></Link>
                </a>
                <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to={"/"} className="nav-item nav-link active">Home</Link>
                        <Link to={"/about"} className="nav-item nav-link ">About</Link>
                        <Link to={"/services"} className="nav-item nav-link ">Services</Link>
                        <Link to={"/newsandevents"} className="nav-item nav-link ">News & Events</Link>
                        <Link to={"/contact"} className="nav-item nav-link ">Contact</Link>
                    </div>
                    <div className="border-start ps-4 d-none d-lg-block">
                        <button type="button" className="btn btn-sm p-0"><i className="fa fa-search"></i></button>
                    </div>
                </div>
            </nav>
            {/*Header*/}


            <div id="demo" className="carousel slide container-fluid px-0 mb-5" data-bs-ride="carousel">

                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                </div>


                <div className="carousel-inner">
                     <div className="carousel-item active">
                        <img src="assets/img/carousel/bioenergycarouselfinal.svg" alt="bio energy" className="d-block w-100" />
                        <div className="carousel-caption">
                            <div className="container ">
                                <h3 className='fs-1 text-white'>Bio-Energy</h3>
                                <p className='fs-4 text-white'>Bio-energy production is dynamic
                                    in the nature, covering almost
                                    every sector of energy
                                    consumption transforming biomass to produce Bio-Gas and
                                    other Products like Ethanol,
                                    Biodiesel and Hydrogen.</p>
                                <Link to={"/bioenergy"} type="button" className="btn btn-warning">Explore More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="assets/img/carousel/wastewatermanagementcarouselfinal.svg" alt="waste water management" className="d-block w-100" />
                        <div className="carousel-caption">
                            <div className="container ">
                                <h3 className='fs-1 text-white'>Waste Water Management</h3>
                                <p className='fs-4 text-white'> We have pioneered ourself in
                                    Waste Water Treatment in a very
                                    natural and indigenous method
                                    with the advancement of our
                                    technology.</p>
                                <Link to={"/wastewatermanagement"} type="button" className="btn btn-warning">Explore More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item ">
                        <img src="assets/img/carousel/esgcarouselfinal.svg" alt="Los Angeles" className="d-block w-100" />
                         <div className="carousel-caption">
                            <div className="container ">
                                <h3 className='fs-1 text-white'>ESG</h3>
                                <p className='fs-4 text-white'>Society is demanding that companies, both private and public, serve a social purpose. Hence,
                                    VSPL strives to embrace Environment, Social and Governance (ESG) pillars in each of its initiatives
                                    and decision making process in order to ensure the sustainability in society.</p>
                                <Link to={"/esg"} type="button" className="btn btn-warning">Explore More</Link>
                            </div>
                        </div>
                    </div>
                </div>


                <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>

            {/* <div className="container-fluid px-0 mb-5">
                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src="assets/img/v.webp" alt="Image"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-8 text-start">
                                            <p className="fs-4 text-white">Welcome to Vasudha Sanrakshan</p>
                                            <h1 className="display-1 text-white mb-5 animated slideInRight">Devoted to serve the Society</h1>
                                            <a href="" className="btn btn-secondary rounded-pill py-3 px-5 animated slideInRight">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src="assets/img/b.webp" alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-end">
                                        <div className="col-lg-8 text-end">
                                            <p className="fs-4 text-white">Bio-energy production is dynamic
                                                in the nature, covering almost
                                                every sector of energy
                                                consumption transforming biomass to produce Bio-Gas and
                                                other Products like Ethanol,
                                                Biodiesel and Hydrogen.
                                            </p>
                                            <h1 className="display-1 text-white mb-5 animated slideInRight">Bio Energy</h1>
                                            <a href="" className="btn btn-secondary rounded-pill py-3 px-5 animated slideInLeft">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src="assets/img/w.webp" alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-end">
                                        <div className="col-lg-8 text-end">
                                            <p className="fs-4 text-white">We have pioneered ourself in
                                                Waste Water Treatment in a very
                                                natural and indigenous method
                                                with the advancement of our
                                                technology.</p>
                                            <h1 className="display-1 text-white mb-5 animated slideInRight">Waste Water Management</h1>
                                            <a href="" className="btn btn-secondary rounded-pill py-3 px-5 animated slideInLeft">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
    </div> */}


            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">

                        <div className="col-lg-6">

                            <div className="video-box rounded">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/i6YXiUkyNQ4?si=RZuXPMTVV2ExGgI4" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </div>

                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <p className="section-title bg-white text-start text-primary pe-3">About Us</p>
                            <h1 className="mb-4">Know About Vasudha Sanrakshan</h1>
                            <p className="mb-4">Empowering a Sustainable Tomorrow: Vasudha Sanrakshan - Pioneering Bioenergy and Water Waste Management Solutions</p>

                            <Link to={"/about"} type="button" className="btn btn-warning">Explore More</Link>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <p className="section-title bg-white text-start text-primary pe-3">Why Us!</p>
                            <h1 className="mb-4">Few Reasons Why People Choosing Us!</h1>
                            <p className="mb-4">We deal with actual problems of the Society by deploying our units to the ground and Solve them with an end to end solution that is carbon neutral. We provide a self-sustaining method with a feedback path with a scope for betterment. </p>
                            <p><i className="fa fa-check text-primary me-3"></i> Collecting Data and Sampling.</p>
                            <p><i className="fa fa-check text-primary me-3"></i> Introducing Green Technology.</p>
                            <p><i className="fa fa-check text-primary me-3"></i>Developing Circular Economy.</p>
                            <p><i className="fa fa-check text-primary me-3"></i> Environmental Accounting [ESG].</p>
                            <Link to={"/services"} type="button" className="btn btn-secondary rounded-pill py-3 px-5 mt-3">Explore More</Link>
                        </div>
                        <div className="col-lg-6">
                            <div className="rounded overflow-hidden">
                                <div className="row g-0">
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                        <div className="text-center bg-primary py-5 px-4">
                                            <img className="img-fluid mb-4" src="assets/img/experience.png" alt="" />
                                            <h1 className="display-6 text-white" data-toggle="counter-up">5</h1>
                                            <span className="fs-5 fw-semi-bold text-secondary">Years Experience</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                        <div className="text-center bg-secondary py-5 px-4">
                                            <img className="img-fluid mb-4" src="assets/img/projectcompleted.svg" style={{ width: '64px', height: '64px' }} alt="" />
                                            <h1 className="display-6" data-toggle="counter-up">27</h1>
                                            <span className="fs-5 fw-semi-bold text-primary">Project Completed</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                        <div className="text-center bg-secondary py-5 px-4">
                                            <img className="img-fluid mb-4" src="assets/img/client.svg" style={{ width: '64px', height: '64px' }} alt="" />
                                            <h1 className="display-6" data-toggle="counter-up">10</h1>
                                            <span className="fs-5 fw-semi-bold text-primary">Esteemed Partner</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                        <div className="text-center bg-primary py-5 px-4">
                                            <img className="img-fluid mb-4" src="assets/img/watertreated.svg" style={{ width: '64px', height: '64px' }} alt="" />
                                            <h1 className="display-6 text-white" data-toggle="counter-up">38k+ MLD</h1>
                                            <span className="fs-5 fw-semi-bold text-secondary">Waste Water Treated</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid banner my-5 py-5" data-parallax="scroll" data-image-src="assets/img/vsp.webp">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.3s">
                            <div className="row g-4 align-items-center">
                                <div className="col-sm-4">
                                    <img className="img-fluid rounded" src="assets/img/circular.webp" alt="Image" />
                                </div>
                                <div className="col-sm-8">
                                    <h2 className="text-white mb-3">Circular Economy</h2>
                                    <p className="text-white mb-4">Now we have involved ourself in
                                        achieving a circular economy in
                                        Waste Water Treatment sector
                                        as a part of mission: “Arth ganga”
                                        and in Green Energy by
                                        developing and formalizing the
                                        chain from raw material
                                        availability, to use of end
                                        products.
                                    </p>
                                    <Link to={"/circulareconomy"} type="button" className="btn btn-warning">Explore More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="row g-4 align-items-center">
                                <div className="col-sm-4">
                                    <img className="img-fluid rounded" src="assets/img/tsociety.webp" alt="Image" />
                                </div>
                                <div className="col-sm-8">
                                    <h2 className="text-white mb-3">Transforming Society</h2>
                                    <p className="text-white mb-4">We are ensuring people’s
                                        participation for awareness in the
                                        sector by organizing several
                                        events thorough educational
                                        institutes and NGOs.
                                    </p>
                                    <Link to={"/transformingsociety"} type="button" className="btn btn-warning">Explore More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <p className="section-title bg-white text-center text-primary px-3">Our Services</p>
                        <h1 className="mb-5">Services That We Offer</h1>
                    </div>
                    <div className="row gy-5 gx-4">
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/bioservice.svg" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/bioenergyicon.svg" alt="" />
                                    </div>
                                    <h5 className="mb-3">Bio Energy</h5>
                                    <p className="mb-4">Bio-energy production is dynamic
                                        in the nature, covering almost
                                        every sector of energy
                                        consumption transforming biomass to produce Bio-Gas and
                                        other Products like Ethanol,
                                        Biodiesel and Hydrogen.</p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/bioenergy"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/1s.webp" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/watericon.svg" alt="" />
                                    </div>
                                    <h5 className="mb-3">Waste Water Management</h5>
                                    <p className="mb-4">We have pioneered ourself in
                                        Waste Water Treatment in a very
                                        natural and indigenous method
                                        with the advancement of our
                                        technology.</p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/wastewatermanagement"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item d-flex h-100">
                                <div className="service-img">
                                    <img className="img-fluid" src="assets/img/esgservice.svg" alt="" />
                                </div>
                                <div className="service-text p-5 pt-0">
                                    <div className="service-icon">
                                        <img className="img-fluid rounded-circle" src="assets/img/esgiconimg1.svg" alt="" />
                                    </div>
                                    <h5 className="mb-3">ESG</h5>
                                    <p className="mb-4">Society is demanding that companies, both private and public, serve a social purpose. Hence, VSPL strives to embrace Environment, Social and Governance (ESG) pillars in each of its initiatives and decision making process in order to ensure the sustainability in society. </p>
                                    <Link clLinkssName="btn btn-square rounded-circle" to={"/esg"}><i className="bi bi-chevron-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="container-xxl py-5 px-0">
                <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">VSPL</p>
                    <h1 className="mb-5">We are working with</h1>
                </div>
            <div className="container">
    <div className="row">
        <div className="col-md-2">
            <img src="assets/img/collaboration/1.svg" alt="Image 1" className="img-fluid" />
            <p>HAREDA</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/2.svg" alt="Image 2" className="img-fluid" />
            <p>Council of Science and Technology, GoUP</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/3.svg" alt="Image 3" className="img-fluid" />
            <p>Indian Railways</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/4.svg" alt="Image 4" className="img-fluid" />
            <p>NITTTR</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/5.svg" alt="Image 5" className="img-fluid" />
            <p>MNNIT</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/6.svg" alt="Image 6" className="img-fluid" />
            <p>UPNEDA</p>
        </div>
    </div>

    <div className="row">
        <div className="col-md-2">
            <img src="assets/img/collaboration/7.svg" alt="Image 7" className="img-fluid" />
            <p>Urban Local Bodies, GoUP</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/8.svg" alt="Image 8" className="img-fluid" />
            <p>UP Jal Nigam</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/9.svg" alt="Image 9" className="img-fluid" />
            <p>IIT Bombay</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/10.svg" alt="Image 10" className="img-fluid" />
            <p>CCSHAU</p>
        </div>
       {/* 
        <div className="col-md-2">
            <img src="assets/img/collaboration/11.svg" alt="Image 11" className="img-fluid" />
            <p>Additional Organization</p>
        </div>
        <div className="col-md-2">
            <img src="assets/img/collaboration/12.svg" alt="Image 12" className="img-fluid" />
            <p>Another Organization</p>
        </div> */}
    </div>
</div>
</div>

            
            <div className="container-xxl py-5 px-0">
                <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">Gallery</p>
                    <h1 className="mb-5">Some Pictures from VSPL</h1>
                </div>
                <div className="row g-0">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/bg12.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/bg12.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/bg13.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/bg13.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G1.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G1.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G2.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G2.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G3.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G3.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-3">
                                    <a href="assets/img/vspgallery/G4.jpg" data-lightbox="gallery">
                                        <img className="card-img-top" src="assets/img/vspgallery/G4.jpg" alt="" style={{ width: '380px', height: '200px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>


                </div><br></br>
                <Link to={"/gallery"} type="button" className="btn btn-warning">Explore More</Link>
            </div>





            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <p className="section-title bg-white text-center text-primary px-3">Our Team</p>
                        <h1 className="mb-5">Experienced Team Members</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-3 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="team-item rounded p-4">
                                <img className="img-fluid rounded mb-4" src="assets/img/team/sandeep.svg" alt="" />
                                <h5>Sandeep Pandey</h5>
                                <p className="text-primary">President</p>
                                <div className="d-flex justify-content-center">
                                    
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="mailto:sandeep@vasudhasanrakshan.com"><i className="fab fa fa-envelope"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="team-item rounded p-4">
                                <img className="img-fluid rounded mb-4" src="assets/img/team/kunwar.svg" alt="" />
                                <h5>Kunwar Ashish P. Singh</h5>
                                <p className="text-primary">Executive Manager</p>
                                <div className="d-flex justify-content-center">
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="https://www.linkedin.com/in/kunwar-ashish-pratap-singh-362830150/"><i className="fab fa-linkedin"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="mailto:admin_renewables@vasudhasanrakshan.com"><i className="fab fa fa-envelope"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="team-item rounded p-4">
                                <img className="img-fluid rounded mb-4" src="assets/img/team/shaifali.svg" alt="" />
                                <h5>Shaifali Gupta</h5>
                                <p className="text-primary">Secretary and Finance Manager</p>
                                <div className="d-flex justify-content-center">
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="https://www.linkedin.com/in/shaifali-gupta09"><i className="fab fa-linkedin"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="https://twitter.com/shaifali136"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="mailto:shaifali@vasudhasanrakshan.com"><i className="fab fa fa-envelope"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="team-item rounded p-4">
                                <img className="img-fluid rounded mb-4" src="assets/img/team/preran.svg" alt="" />
                                <h5>Prerna Sahu</h5>
                                <p className="text-primary">Manager and Head (Operations)</p>
                                <div className="d-flex justify-content-center">
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="https://www.linkedin.com/in/prerna-sahu-296a04137"><i className="fab fa-linkedin"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="https://twitter.com/PrernaSahu11"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-secondary rounded-circle mx-1" href="mailto:prerna@vasudhasanrakshan.com"><i className="fab fa fa-envelope"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/**Testimonial */}

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <p className="section-title bg-white text-center text-primary px-3">Testimonial</p>
                        <h1 className="mb-5">What People Say About Vasudha Sanrakshan</h1>
                    </div>
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="testimonial-img">
                                <img className="img-fluid animated pulse infinite" src="assets/img/team/sandeepsir.svg" alt="" />
                                <img className="img-fluid animated pulse infinite" src="assets/img/team/sudeep.svg" alt="" />
                                <img className="img-fluid animated pulse infinite" src="assets/img/" alt="" />
                                <img className="img-fluid animated pulse infinite" src="assets/img/" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">

                            <TestimonialSlider />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Index;