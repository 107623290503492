import React from 'react';
import './App.css';
import Index from './pages/index';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import About from './pages/about';
import Services from './pages/services';
import Testimonial from './pages/testimonial'
import Contact from './pages/contact';
import Bioenergy from './pages/bioenergy';
import Wastewatermanagement from './pages/wastewatermanagement';
import Newsandevents from './pages/newsandevents';
import Esg from './pages/esg';
import Gallery from './pages/gallery';
import Circulareconomy from './pages/circulareconomy';
import Transformingsociety from './pages/transformingsociety';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Index />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/services' element={<Services />}/>
          <Route path='/testimonial' element={<Testimonial/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/bioenergy' element={<Bioenergy/>}/>
          <Route path='/wastewatermanagement' element={<Wastewatermanagement/>}/>
          <Route path='/newsandevents' element={<Newsandevents/>}/>
          <Route path='/esg' element={<Esg/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/circulareconomy' element={<Circulareconomy/>}/>
          <Route path='/transformingsociety' element={<Transformingsociety/>}/>
        </Routes>
        </Router>
    </div>
  );
}

export default App;
