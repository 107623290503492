import React, { Fragment } from 'react';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';
import Event from './event';


function Newsandevents() {
    return (
        <Fragment>


            <div className="container-fluid bg-dark px-0">
                <div className="row g-0 d-none d-lg-flex">
                    <div className="col-lg-6 ps-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center text-light">
                            <span>Follow Us:</span>
                            <a className="btn btn-link text-light" href="https://www.facebook.com/vasudhasanrakshan?mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-link text-light" href="https://twitter.com/KAPS_Vasudha"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-link text-light" href="https://www.linkedin.com/company/80557301/admin/feed/posts/"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-link text-light" href="https://www.instagram.com/vasudhasanrakshan/?igshid=NzZlODBkYWE4Ng%3D%3D"><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-link text-light" href="https://www.youtube.com/@user-be7dz9jx7d"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div className="h-100 bg-secondary d-inline-flex align-items-center text-dark py-2 px-4">
                            <span className="me-2 fw-semi-bold"><i className="fa fa-phone-alt me-2"></i>Call Us:</span>
                            <span>+91-7860402234</span>
                        </div>
                    </div>
                </div>
            </div>




            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
                <a href="index.html" className="navbar-brand d-flex align-items-center" style={{ color: '#158248' }}>
                    {/*<h4 className="m-0"><Link to={"/"}><img  src="assets/img/logo.png" alt="" style={{ height: '70px' ,width:'auto' }}/> Vasudha Sanrakshan</Link></h4>*/}
                    <Link to={"/"}><img src="assets/img/logo/finallogo.webp" alt="" className="m-0" style={{ height: '50px', width: '225px' }} /></Link>
                </a>
                <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to={"/"} className="nav-item nav-link ">Home</Link>
                        <Link to={"/about"} className="nav-item nav-link ">About</Link>
                        <Link to={"/services"} className="nav-item nav-link ">Services</Link>
                        <Link to={"/newsandevents"} className="nav-item nav-link active">News & Events</Link>
                        <Link to={"/contact"} className="nav-item nav-link ">Contact</Link>
                    </div>
                    <div className="border-start ps-4 d-none d-lg-block">
                        <button type="button" className="btn btn-sm p-0"><i className="fa fa-search"></i></button>
                    </div>
                </div>
            </nav>


            <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{
                backgroundImage: "url('assets/img/newsandevents.svg')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <div className="container text-center py-5">
                    <h1 className="display-3 text-white mb-4 animated slideInDown">News And Events</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">News & Events</a></li>
                        </ol>
                    </nav>
                </div>
            </div>


         
            <div className="row">
                <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">VSPL</p>
                    <h1 className="mb-5">Some Upcoming Events</h1>
                </div>

                <Event />
            </div>





            <div className="container mt-5">
                <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">VSPL</p>
                    <h1 className="mb-5">News & Previous Events</h1>
                </div>
                <div className="row">

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/1.svg" className="card-img-top" alt="News Image" />
                            <div className="card-body">
                                <h5 className="card-title">Event</h5>
                                <p className="card-text">Experience sharing and presentation on "Career opportunities in Environmental Sectors" for Students of Bundelkhand University, Jhansi, UP.</p>
                                <a href="#" className="btn btn-primary">September 2023</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/2.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Event</h5>
                                {/** */}
                                <p className="card-text">Self-Awareness program by VSPL at Gorakhpur District for treatment of Wastewater to keep River Ghaghara Clean.</p>
                                <a href="#" className="btn btn-primary">November 2021</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/3.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Site Presentation to Additional Chief Secretary, , Shri Anil Kumar, IAS, for in-situ water treatment program.</p>
                                <a href="#" className="btn btn-primary">July 2021</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/4.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Staff training program at Shri Maha prabhu Public School, Prayagraj, for One Month to train them for segregation and Material Recovery of Solid Waste generated at the  premises.</p>
                                <a href="#" className="btn btn-primary">January 2020</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/5.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Our team member Miss Shaifali Gupta was UP Youth Icon in 2020.</p>
                                <a href="#" className="btn btn-primary">2020</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/6.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Presentation and Experience sharing by VSPL on “Steps in acquiring a business” at Agri-preneurship Orientation Program (AOP) of RKVY-RAFTAAR scheme of CCSHAU, Hisar.</p>
                                <a href="#" className="btn btn-primary">August 2019</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/7.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Signed an MOU with Chaudhary Charan Singh Haryana Agriculture University, Hisar for developing Bioenergy in the State.</p>
                                <a href="#" className="btn btn-primary"> July 2019</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/8.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Awarded as Utkrisht Seva Samman by Swami Chidanand Saraswati ji Maharaj, Parmarth Niketan, Rishikesh.</p>
                                <a href="#" className="btn btn-primary">January to march 2019 </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/9.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Material Recovery Facility Work at Kumbh 2019 and training to Rag Pickers.</p>
                                <a href="#" className="btn btn-primary">January to march 2019 </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/10.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">Presenting Honorable NGT team for Material Recovery Facility work done by VSPL at Waste Processing Plant, Prayagraj. </p>
                                <a href="#" className="btn btn-primary">February 2019 </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/11.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">A campaign by VSPL on “Save Ganga River Dolphins from pollution” with demonstration through Waste Plastic Bottles under ‘Paint my city’ </p>
                                <a href="#" className="btn btn-primary">January to march 2019 </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/12.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">VSPL at UP Investor Submit 2018, Lucknow.</p>
                                <a href="#" className="btn btn-primary">2018 </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card mb-4">
                            <img src="assets/img/events/13.svg" className="card-img-top" alt="Event Image" />
                            <div className="card-body">
                                <h5 className="card-title">Previous Event</h5>
                                <p className="card-text">VSPL Hosts seminar on Zero Budget Natural Farming and Management of Post Harvest.</p>
                                <a href="#" className="btn btn-primary">2018 </a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <Footer />
        </Fragment>
    )
}

export default Newsandevents;