// src/components/TestimonialSlider.js

import React, { useState } from 'react';
import './testimonialslider.css';

const testimonials = [
  {
    id: 1,
    image:'assets/img/team/sudeep.svg',
    name: 'Shri Sudeep Singh, IRTS',
    text: 'Work of waste disposal at Charbagh Railway station, Lucknow was successfully completed  by Vasudha Sanrakshan Private Limited for four months i.e. September 2018  to December 2018.  Work was awarded by the commercial department of DRM office Northern Railway and initiated under the supervision of Station Director Lucknow. This was the first of its kind in Indian Railways. My best wishes to Vasudha Sanrakshan for taking similar works of waste management in future and saving Earth from pollution',
  },
  {
    id: 2,
    name: 'President  Varisth Global Foundation Lucknow',
    text: 'Vasudha Sanrakshan is developing a method and system for circular economy in two important sectors: wastewater treatment and bio-energy for the last 5 years by formalizing the chain, necessary for monetization of resources at every level We are happy to be partners with each other. And further, we as a team, hope best for Bharats sustainable development',
  },
  {
    id: 2,
    image:'assets/img/team/sandeepsir.svg',
    name: 'Shri Sandeep Dwivedi Innovation OfficerCouncil of Science and Technology, Lucknow',
    text: 'I am providing mentorship to Vasudha Sanrakshan Private Limited for 5 years i.e., from June 2018 to present. Startup is working wonderfully in the Environment and Renewable Energy Sector with its innovative technology and systematic approach to collect waste source segregated solid waste from waste generators and after its proper scientific disposal. Your creativity and courage have eventually led you to open your own startup, and I’m so proud of you. I have every reason to believe that your hard work and perseverance will lead to the success of this startup. Congratulations and good luck for your startup',
  },
  // Add more testimonials here
];

const TestimonialSlider = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((currentTestimonial + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((currentTestimonial - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="testimonial-slider">
      <div className="testimonial">
      <img src={testimonials[currentTestimonial].image} className=" timg img-fluid animated pulse infinite"/>
      <p className="testimonial-text">{testimonials[currentTestimonial].text}</p>
      <p className="testimonial-author">- {testimonials[currentTestimonial].name}</p>
      </div>
      <div className="testimonial-controls">
        <button onClick={prevTestimonial}>&#8249;</button>
        <button onClick={nextTestimonial}>&#8250;</button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
